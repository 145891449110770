import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { withTranslation } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import StoreContext from 'src/context/StoreContext'
import { IN_AU, IN_CA, IS_FOREIGN_COUNTRY, LOCALIZATION } from '../utils/constants'

import Hero from '../components/Hero'
import SEO from '../components/seo'
import FramePlusFeatures from '../components/FramePlusFeatures'
import FramePress from '../components/FramePress'
import CalendarTestimonials from '../components/CalendarTestimonials'
import CountDownTimer from '../components/CountDownTimer'
import FAQs from '../components/FAQs'
import EffortlessToUse from '../components/EffortlessToUse'
import Guarantee from '../components/Guarantee'
import FreeReturns from '../components/FreeReturns'
import PerfectGift from '../components/PerfectGift'
import { FEATURES } from '../components/FramePlusFeatures/constants'
import { FRAME_FEATURES } from '../components/FrameFeatures/constants'
import { HideOverflow } from '../utils/styles/global-styles'
import Analytics from '../utils/Analytics'
import FrameFeatures from '../components/FrameFeatures'

class FramePage extends React.Component {
  componentDidMount() {
    Analytics.track('Viewed Frame Landing Page')
    if (!IS_FOREIGN_COUNTRY) {
      try {
        const url = new URL(window.location.href)
        const urlParams = new URLSearchParams(url.search)
        const utmCampaign = urlParams.get('utm_campaign')
        const utmTerm = urlParams.get('utm_term')

        if (utmCampaign === 'today' || utmTerm === 'today') {
          const { updateBanner } = this.context
          const promoCode = 'TODAY'
          const channel = 'Today'
          const amount = '20%'
          updateBanner(promoCode, channel, amount)
        }
      } catch (error) {
        console.error('Invalid URL:', error)
      }
    }
  }

  render() {
    const { t } = this.props
    const holidayDate = {
      en: 'May 6, 2024 23:59:00',
      'en-au': 'May 2, 2024 23:59:00',
      'en-ca': 'May 5, 2024 23:59:00',
    }
    return (
      <>
        <SEO
          title={t('Digital Picture Frames | Skylight Frame')}
          description={t(
            'Skylight is a WiFi-enabled, touchscreen digital frame that lets you easily send pictures to your loved ones by email or mobile app.'
          )}
          path="/frame/"
        />
        <Helmet>
          <meta charset="UTF-8" />
          <link rel="canonical" href="https://www.skylightframe.com/" />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="//cdn.jsdelivr.net/npm/slick-carousel@1.8.1/slick/slick-theme.css"
          />
        </Helmet>
        <HideOverflow>
          <Hero />
          <FramePress />
          <EffortlessToUse title={t('Delightfully Effortless To Use')} />
          <PerfectGift />
          <Guarantee />
          <CalendarTestimonials page="frame" title="Families Rave About Skylight Frame" />
          {!IS_FOREIGN_COUNTRY ? (
            <FrameFeatures features={FRAME_FEATURES} />
          ) : (
            <FramePlusFeatures features={FEATURES} />
          )}
          {!IS_FOREIGN_COUNTRY && <FreeReturns />}
          <FAQs page="frame" />
        </HideOverflow>
      </>
    )
  }
}

FramePage.contextType = StoreContext

FramePage.propTypes = {
  t: PropTypes.func,
}

export default withTranslation()(FramePage)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["common", "frame"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
